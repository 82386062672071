<template>
    <main class="dish">
        <router-view class="dish__data" :userRestaurants="userRestaurants" />
    </main>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "Dish",
        computed: {
            ...mapGetters([
                "userRestaurants"
            ])
        }
    }
</script>
